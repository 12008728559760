import { render, staticRenderFns } from "./warn-info-import.vue?vue&type=template&id=4fe4a27a&scoped=true"
import script from "./warn-info-import.vue?vue&type=script&lang=ts"
export * from "./warn-info-import.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe4a27a",
  null
  
)

export default component.exports